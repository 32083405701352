<template>
  <div class="viewDashBoard">
    <transition
      name="fade"
      mode="out-in"
      @beforeLeave="beforeLeave"
      @enter="enter"
      @afterEnter="afterEnter"
    >
      <div key="1" v-if="loader" class="white-row loader-route">
        <div class="lds-sub-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
      <div v-else key="2" class="container">
        <div class="white-row">
          <div class="general-filters">
            <div class="col-sm-12">
              <div class="row">
                <div class="col-sm-8">
                  <div class="row top-gen-heading">
                    <h1 class="global-style-heading">
                      Messages
                      <a
                        @click.prevent="refreshMessagesTable"
                        href=""
                        class="refreshData"
                        ><i class="fas fa-redo-alt"></i
                      ></a>
                    </h1>
                  </div>
                </div>
                <div class="col-sm-4">
                  <form>
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="form-group">
                            <label for="gender" class="control-label"
                              >&nbsp;</label
                            >
                            <div class="input-group">
                              <input
                                v-model="formData.search"
                                @keyup="getThreadMessageData(1)"
                                type="text"
                                class="form-control"
                                placeholder="Search..."
                              />
                              <div class="input-group-append">
                                <button class="btn btn-secondary" type="button">
                                  <i class="fa fa-search"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="horizontal-row">
          <message-header />
            <hr />
          </div>
          <section class="thread-listing">
            <transition
              name="fade"
              mode="out-in"
              @beforeLeave="beforeLeave"
              @enter="enter"
              @afterEnter="afterEnter"
            >
              <div key="1" v-if="data_loader" class="white-row loader-route">
                <div class="lds-sub-ripple">
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div v-else>
                <div
                  class="thread-wrapper"
                  v-if="
                    typeof thread_messages_data.data !== 'undefined' &&
                      Object.keys(thread_messages_data.data).length
                  "
                >
                  <div
                    v-for="(mes, mes_index) in thread_messages_data.data"
                    :key="mes_index"
                    class="thr-loop-row"
                  >
                    <div class="row">
                      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <div class="thrd-name">
                          <router-link
                            class="eclipse-para color-blk customize-global-link gl-lk-blk"
                            :to="{
                              path: '/messages/show/' + mes.id
                            }"
                            tag="a"
                          >
                            {{ mes.porject_thread_subtitle }} ({{
                              mes.thread_message_total_count
                            }})
                          </router-link>
                        </div>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-8 col-sm-12">
                        <div class="row">
                          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                            <div class="per-name">
                              <p class="eclipse-para">
                                {{ mes.last_message.user.name }}
                              </p>
                            </div>
                          </div>
                          <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12">
                            <div class="pers-last-mesg">
                              <p
                                class="eclipse-para"
                                v-html="mes.last_message.message"
                              ></p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-2 col-lg-2 col-md-4 col-sm-12">
                        <div
                          class="thr-time text-right"
                          v-text="mes.last_message.created_at"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="thread-wrapper">
                  <no-record-found></no-record-found>
                </div>
              </div>
            </transition>

            <div class="row">
              <div class="col-sm-12">
                <pagination
                  class="pagination justify-content-center"
                  :data="thread_messages_data"
                  :limit="1"
                  @pagination-change-page="getThreadMessageData"
                ></pagination>
              </div>
            </div>
          </section>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import norecordfound from "../../../components/no-record-found";
import messageHeader from "../../../components/message-header";
export default {
  components: {
    "no-record-found": norecordfound,
    messageHeader
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  data() {
    return {
      thread_messages_data: {},
      loader: true,
      data_loader: true,
      formData: {
        search: ""
      }
    };
  },
  mounted() {
    document.title = this.$router.app._route.meta.pageTitle;
    this.getThreadMessageData();
    this.loader = false;
  },
  methods: {
    refreshMessagesTable() {
      this.getThreadMessageData();
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
    async getThreadMessageData(page = 1, action = null) {
      const $this = this;
      $this.data_loader = true;
      const data = $this.$store.dispatch("getHttp", {
        url:
          "thread-message" +
          "?" +
          "&page=" +
          page +
          "&search=" +
          this.formData.search +
          "&timezone=" +
          Intl.DateTimeFormat().resolvedOptions().timeZone
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.thread_messages_data = response.data;
          $this.data_loader = false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
ul.pagination.pagination.justify-content-center {
  padding: 25px 0 0;
  margin: 0;
}
body .white-row.loader-route {
  top: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}
body .horizontal-row {
  padding: 30px 0 10px;
}
.general-filters {
  .form-group {
    width: 95%;
    .input-group {
      input {
        border-radius: 3px !important;
        background: #f9f9f9;
        letter-spacing: 0.28px;
        font-size: 14px;
        padding: 3px 15px;
        color: #364460;
        border: none;
        font-weight: 200;
        height: auto;
      }
      button {
        background: #f9f9f9;
        color: #3b4c54;
        border: none;
        padding: 4px 8px;
      }
    }
  }
  .form-actions {
    width: 100%;
    button {
      width: 100%;
      margin-left: 0;
      padding: 5px 0;
    }
  }
  span.select2.select2-container.select2-container--default {
    width: 100% !important;
  }
}
section {
  &.thread-listing {
    border: none;
    padding-bottom: 0;
    padding-top: 0;
    .thr-loop-row {
      border-bottom: 1px solid #ebebeb;
      padding: 20px 0;
      p {
        margin: 0;
        letter-spacing: 0.28px;
        color: #2a1c0b;
      }
      .thrd-name {
        p {
          letter-spacing: 0.28px;
          color: #323f54;
          font-weight: bold;
        }
      }
      .per-name {
        p {
          font-weight: 400;
        }
      }
      .pers-last-mesg {
        p {
          font-weight: 100;
        }
      }
      .thr-time {
        font-weight: 100;
      }
    }
  }
}
</style>
