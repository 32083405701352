import { render, staticRenderFns } from "./projectcoordinators_vue.vue?vue&type=template&id=eb6c7d86&scoped=true&"
import script from "./projectcoordinators_vue.vue?vue&type=script&lang=js&"
export * from "./projectcoordinators_vue.vue?vue&type=script&lang=js&"
import style0 from "./projectcoordinators_vue.vue?vue&type=style&index=0&id=eb6c7d86&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb6c7d86",
  null
  
)

export default component.exports