<template>
  <div class="viewDashBoard" v-cloak>
    <div class="container">
      <div
        v-if="
          typeof getActiveUserData.user !== 'undefined' &&
            Object.keys(getActiveUserData.user).length
        "
      >
        <admin-projects-messages-vue
          v-if="(getActiveUserData.userRoles == 'admin') || (getActiveUserData.userRoles == 'subadmin')"
        ></admin-projects-messages-vue>
        <projectcoordinators-messages-vue
          v-else-if="getActiveUserData.userRoles == 'projectcoordinators'"
        ></projectcoordinators-messages-vue>
        <saleateamlead-vue-projects-messages-vue
          v-else-if="(getActiveUserData.userRoles == 'salesteamlead') || (getActiveUserData.userRoles == 'salesmanager')"
        ></saleateamlead-vue-projects-messages-vue>
        <salesperson-vue-projects-messages-vue
          v-else-if="getActiveUserData.userRoles == 'salesperson'"
        ></salesperson-vue-projects-messages-vue>
        <client-vue-projects-messages-vue
          v-else-if="getActiveUserData.userRoles == 'client'"
        ></client-vue-projects-messages-vue>
        <no-component-found v-else></no-component-found>
      </div>
      <div v-else class="white-row loader-route">
        <div class="lds-sub-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import adminvue from "./components/admin_vue";
import saleateamlead_vue from "./components/saleateamlead_vue";
import noComponentFound from "../../components/noComponentFound";
import salesperson_vue from "./components/salesperson_vue";
import client_vue from "./components/client_vue";
import projectcoordinators_vue from "./components/projectcoordinators_vue.vue";

export default {
  components: {
    "no-component-found": noComponentFound,
    "admin-projects-messages-vue": adminvue,
    "saleateamlead-vue-projects-messages-vue": saleateamlead_vue,
    "salesperson-vue-projects-messages-vue": salesperson_vue,
    "client-vue-projects-messages-vue": client_vue,
    "projectcoordinators-messages-vue" : projectcoordinators_vue,
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  data() {
    return {};
  },
  mounted() {
    document.title = this.$router.app._route.meta.pageTitle;
  },
  methods: {}
};
</script>
